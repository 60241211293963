:root {
  --mainPurple: rgba(66,23,208, .85);
  --lightBlue: #009ffd;
  --mainWhite: #ffffff;
  --mainDark: #232528;
  --mainYellow: #9d9200;
}

html {
  height: 100%
}

body {
  height: 100%;
  min-height: 35rem;
  position: relative;
  font-family: 'Alegreya', serif;
  font-weight: 300
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Merriweather;
  font-weight: 700
}
